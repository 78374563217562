var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    {
      attrs: {
        "form-action-method": _vm.formActionWindow,
        "form-actions": _vm.formActions,
        "form-rop": _vm.rop,
        "is-form-submitted": _vm.formSubmitted,
        "page-path": _vm.pagePath,
        title: _vm.title,
        "title-icon": _vm.formActionIcon,
      },
    },
    [
      _c("asyent-form", {
        ref: _vm.formRefAE,
        attrs: {
          "data-error": _vm.dataError,
          "data-loading": _vm.dataLoading,
          errors: _vm.errors,
          "form-action-method": _vm.formActionMethod,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          readonly: _vm.isReadonly,
          reference: _vm.formRef,
          success: _vm.success,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (props) {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              expandable: "",
                              color: "cardPrimary",
                              title: "General",
                            },
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "9",
                                              md: "6",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("asyent-form-text", {
                                              attrs: {
                                                "form-errors": _vm.formErrors,
                                                "form-rules":
                                                  _vm.formFieldsRules,
                                                "is-disabled-func":
                                                  _vm.isDisabled,
                                                props: props,
                                                label: "Office Code",
                                                name: "officeCode",
                                              },
                                              model: {
                                                value:
                                                  _vm.formDataF["officeCode"],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formDataF,
                                                    "officeCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formDataF['officeCode']",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "12",
                                              lg: "9",
                                            },
                                          },
                                          [
                                            _c("asyent-form-text", {
                                              attrs: {
                                                "form-errors": _vm.formErrors,
                                                "form-rules":
                                                  _vm.formFieldsRules,
                                                "is-disabled-func":
                                                  _vm.isDisabled,
                                                props: props,
                                                label: "Office Name",
                                                name: "officeName",
                                              },
                                              model: {
                                                value:
                                                  _vm.formDataF["officeName"],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formDataF,
                                                    "officeName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formDataF['officeName']",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "12",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("asyent-form-select", {
                                              attrs: {
                                                "form-errors": _vm.formErrors,
                                                "form-rules":
                                                  _vm.formFieldsRules,
                                                "is-disabled-func":
                                                  _vm.isDisabled,
                                                "items-reload":
                                                  _vm.countryReload,
                                                props: props,
                                                "drop-down": "",
                                                "item-text": "ctyDsc",
                                                "item-value": "ctyCod",
                                                label: "Country",
                                                mandatory: "",
                                                name: "country",
                                                "show-item-avatar": "",
                                                sortable: "",
                                              },
                                              model: {
                                                value: _vm.formDataF["country"],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formDataF,
                                                    "country",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formDataF['country']",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "12",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("asyent-form-select", {
                                              attrs: {
                                                "form-errors": _vm.formErrors,
                                                "form-rules":
                                                  _vm.formFieldsRules,
                                                "is-disabled-func":
                                                  _vm.isDisabled,
                                                "items-reload":
                                                  _vm.countryReload,
                                                props: props,
                                                "drop-down": "",
                                                "item-text": "ctyDsc",
                                                "item-value": "ctyCod",
                                                label: "Bordering Country",
                                                mandatory: "",
                                                name: "borderingCountry",
                                                "show-item-avatar": "",
                                                sortable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.formDataF[
                                                    "borderingCountry"
                                                  ],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formDataF,
                                                    "borderingCountry",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formDataF['borderingCountry']",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "12",
                                              lg: "6",
                                            },
                                          },
                                          [
                                            _c("asyent-form-select", {
                                              attrs: {
                                                "form-errors": _vm.formErrors,
                                                "form-rules":
                                                  _vm.formFieldsRules,
                                                "is-disabled-func":
                                                  _vm.isDisabled,
                                                "items-reload":
                                                  _vm.locodeReload,
                                                props: props,
                                                "drop-down": "",
                                                "item-text": "locDsc",
                                                "item-value": "locCod",
                                                label: "Location",
                                                name: "location",
                                                sortable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.formDataF["location"],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formDataF,
                                                    "location",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formDataF['location']",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "12",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("asyent-form-text", {
                                              attrs: {
                                                "form-errors": _vm.formErrors,
                                                "form-rules":
                                                  _vm.formFieldsRules,
                                                "is-disabled-func":
                                                  _vm.isDisabled,
                                                props: props,
                                                label: "Bordering Office",
                                                name: "borderingOffice",
                                              },
                                              model: {
                                                value:
                                                  _vm.formDataF[
                                                    "borderingOffice"
                                                  ],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formDataF,
                                                    "borderingOffice",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formDataF['borderingOffice']",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "12",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("asyent-form-date", {
                                              attrs: {
                                                "form-errors": _vm.formErrors,
                                                "form-rules":
                                                  _vm.formFieldsRules,
                                                "is-disabled-func":
                                                  _vm.isDisabled,
                                                props: {},
                                                label: "Start Date",
                                                name: "startDate",
                                              },
                                              model: {
                                                value:
                                                  _vm.formDataF["startDate"],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formDataF,
                                                    "startDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formDataF['startDate']",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "12",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("asyent-form-date", {
                                              attrs: {
                                                "form-errors": _vm.formErrors,
                                                "form-rules":
                                                  _vm.formFieldsRules,
                                                "is-disabled-func":
                                                  _vm.isDisabled,
                                                props: {},
                                                label: "End Date",
                                                name: "endDate",
                                              },
                                              model: {
                                                value: _vm.formDataF["endDate"],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formDataF,
                                                    "endDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formDataF['endDate']",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("asyent-form-select", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        "items-reload": _vm.officeRolesReload,
                                        props: props,
                                        "enable-select-all": "",
                                        "item-text": "description",
                                        "item-value": "code",
                                        label: "Office Roles",
                                        multiple: "",
                                        name: "officeRoles",
                                        sortable: "",
                                        title: "Office Roles",
                                      },
                                      model: {
                                        value: _vm.formData["officeRoles"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "officeRoles",
                                            $$v
                                          )
                                        },
                                        expression: "formData['officeRoles']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12" } },
                                  [
                                    _c("asyent-form-select", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        "items-reload": _vm.transportModeReload,
                                        props: props,
                                        "enable-select-all": "",
                                        "item-text": "motDsc",
                                        "item-value": "motCod",
                                        label: "Transport Modes",
                                        multiple: "",
                                        name: "transportModes",
                                      },
                                      model: {
                                        value: _vm.formDataF["transportModes"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "transportModes",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['transportModes']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              expandable: "",
                              color: "cardPrimary",
                              title: "Contact Details",
                            },
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12", lg: "6" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Street And Number",
                                        name: "streetAndNumber",
                                      },
                                      model: {
                                        value: _vm.formDataF["streetAndNumber"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "streetAndNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['streetAndNumber']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12", lg: "3" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Post Code",
                                        name: "postCode",
                                      },
                                      model: {
                                        value: _vm.formDataF["postCode"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "postCode",
                                            $$v
                                          )
                                        },
                                        expression: "formDataF['postCode']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12", lg: "3" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "City",
                                        name: "city",
                                      },
                                      model: {
                                        value: _vm.formDataF["city"],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formDataF, "city", $$v)
                                        },
                                        expression: "formDataF['city']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12", lg: "6" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Email",
                                        name: "email",
                                      },
                                      model: {
                                        value: _vm.formDataF["email"],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formDataF, "email", $$v)
                                        },
                                        expression: "formDataF['email']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12", lg: "3" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Phone Number",
                                        name: "phoneNumber",
                                      },
                                      model: {
                                        value: _vm.formDataF["phoneNumber"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "phoneNumber",
                                            $$v
                                          )
                                        },
                                        expression: "formDataF['phoneNumber']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12", lg: "3" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Fax Number",
                                        name: "faxNumber",
                                      },
                                      model: {
                                        value: _vm.formDataF["faxNumber"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "faxNumber",
                                            $$v
                                          )
                                        },
                                        expression: "formDataF['faxNumber']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("NonWorkingHours", {
                          attrs: {
                            "view-mode": _vm.viewMode,
                            "item-headings": ["From", "To Time", "Recurrence"],
                            "item-values": [
                              "nonWorkingFrom",
                              "nonWorkingTo",
                              "recurrence",
                            ],
                            "is-disabled-func": _vm.isDisabled,
                            readonly: _vm.isReadonly,
                            name: "nonWorkingHours",
                            title: "Non-Working Hours",
                          },
                          model: {
                            value: _vm.formDataF["nonWorkingHours"],
                            callback: function ($$v) {
                              _vm.$set(_vm.formDataF, "nonWorkingHours", $$v)
                            },
                            expression: "formDataF['nonWorkingHours']",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.formValid,
          callback: function ($$v) {
            _vm.formValid = $$v
          },
          expression: "formValid",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }